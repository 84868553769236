import React, { useState } from 'react';
import { ThemeProvider, createTheme, CssBaseline, Box } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import MainContent from './components/MainContent';
import Footer from './components/Footer';
import QRCodeModal from './components/QRCodeModal';
import Costco from './costco/Costco'; 
import UploadReceipts from './costco/UploadReceipts';
import qrCodeProfound from './qrcode-profoundwords.png';
import './App.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

function App() {
  const [qrCodeOpen, setQrCodeOpen] = useState(false);
  const [currentQrCode, setCurrentQrCode] = useState('');
  const [currentQrCodeTitle, setCurrentQrCodeTitle] = useState('');
  const [currentQrCodeDescription, setCurrentQrCodeDescription] = useState('');
  const [currentQrCodeLink, setCurrentQrCodeLink] = useState('');

  const handleQrCodeOpen = (qrCodeData) => {
    setCurrentQrCode(qrCodeData.qrcode);
    setCurrentQrCodeTitle(qrCodeData.title);
    setCurrentQrCodeDescription(qrCodeData.description);
    setCurrentQrCodeLink(qrCodeData.link);
    setQrCodeOpen(true);
  };

  const handleQrCodeClose = () => {
    setQrCodeOpen(false);
  };

  const blocks = [
    { title: 'Bloomberg\'s Phrases', description: 'Empowering Your Financial Vocabulary', image: 'images/bloomberg.png?v=1', link: 'https://bloomberg.profoundwords.us/', qrcode: 'images/qr_bloomberg.png?v=1' },
    // { title: 'President Trump\'s Phrases', description: 'History is written by winners', image: 'images/trump.png?v=1', link: 'https://trump.profoundwords.us/', qrcode: 'images/qr_trump.png?v=1' },
    { title: 'Political Analysis Phrases', description: 'US Political Analysis Phrases', image: 'images/political-analysis.png', link: 'https://pap.profoundwords.us/', qrcode: 'images/qr_pap.png?v=1' },
    { title: 'Situation Idioms', description: 'Chinese Idioms in real-life political scenarios', image: 'images/situation-idioms.png?v=1', link: 'https://situation.profoundwords.us/', qrcode: 'images/qr_situation.png?v=1' },
  ];

  const shareBlocks = [
    { title: 'Cost$$', description: 'Price-Adjustment', image: 'images/cost$$.png', link: './cost$$' },
  ];

  const secondBlocks = [
    { title: 'Mint Mobile', description: 'Referral Link', image: 'images/mint-mobile.jpg', link: 'http://fbuy.me/uy2-s' },
  ];

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box className="app-container">
        <Header onQRCodeClick={() => handleQrCodeOpen({
          qrcode: qrCodeProfound,
          title: 'Profound Words',
          description: 'Data Value-Added AI Service Platform',
          link: 'https://profoundwords.us/'
        })} />
        <Routes>
            <Route path="/" element={<MainContent blocks={blocks} shareBlocks={shareBlocks} secondBlocks={secondBlocks} onQRCodeClick={handleQrCodeOpen} />} />
            <Route path="/cost$$" element={<Costco />} />
            <Route path="/cost$$/upload" element={<UploadReceipts />} />
        </Routes>
        <Footer />
        <QRCodeModal
          open={qrCodeOpen}
          onClose={handleQrCodeClose}
          qrCode={currentQrCode}
          title={currentQrCodeTitle}
          description={currentQrCodeDescription}
          link={currentQrCodeLink}
        />
      </Box>
    </ThemeProvider>
  );
}

export default App;