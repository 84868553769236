import React from 'react';
import { Typography, Container, Grid, Card, CardContent, CardMedia, Box, IconButton } from '@mui/material';
import QrCodeIcon from '@mui/icons-material/QrCode';
import GetAppIcon from '@mui/icons-material/GetApp';
import GoogleAd from './GoogleAd';

const MainContent = ({ blocks, shareBlocks, secondBlocks, onQRCodeClick }) => (
  <Container maxWidth="lg" className="main-content">
    <Typography variant="h6" component="h1" gutterBottom className="intro-heading">
      <i>Recap YouTube Videos with AI Insights: Extracting, Generating, and Inspiring!</i>
    </Typography>

    <Typography variant="subtitle1" gutterBottom className="intro-subtitle">
      <b><u>Learning Websites</u></b>
    </Typography>
    <br />
    <Grid container spacing={2}>
      {blocks.map((block, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <Box
            onClick={() => {
              if (block.link) {
                window.open(block.link, '_blank', 'noopener,noreferrer');
              }
            }}
            className={`card-container ${block.link ? 'clickable' : ''}`}
          >
            <Card className="card">
              <CardContent className="card-content" sx={{ pb: 1 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography 
                    variant="subtitle1"
                    component="h4" 
                    className="card-title" 
                    sx={{ textAlign: 'left', mb: 1, fontSize: '1.2 rem', fontWeight: 700 }}
                  >
                    {block.title}
                  </Typography>
                  {block.qrcode && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onQRCodeClick(block);
                      }}
                      size="small"
                    >
                      <QrCodeIcon />
                    </IconButton>
                  )}
                </Box>
              </CardContent>
              <CardMedia
                component="img"
                image={block.image || 'https://via.placeholder.com/96'}
                alt={block.title}
                sx={{
                  width: 128,
                  height: 128,
                  margin: '0 auto 8px',
                  objectFit: 'contain',
                  borderRadius: '8px',
                }}
              />
              <CardContent className="card-content" sx={{ pt: 0, pb: '16px !important', position: 'relative' }}>
                <Typography 
                  className="card-description" 
                  sx={{ 
                    fontSize: '1 rem', 
                    textAlign: 'left', 
                    color: '#444',
                    ml: 2,
                    mb: 3 // Add margin at the bottom to make space for the download icon
                  }}
                >
                  {block.description}
                </Typography>
                <IconButton
                  component="a"
                  href="https://drive.google.com/drive/folders/1Reu7k1jeZWCixAMn4O9LAQhdT3NzlaRD?usp=drive_link"
                  target="_blank"
                  aria-label="Download Phrases"
                  sx={{
                    position: 'absolute',
                    bottom: 4,
                    right: 4,
                    padding: '4px'
                  }}
                >
                  <GetAppIcon fontSize="small" />
                </IconButton>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      ))}
    </Grid>
    <hr />
    <Typography variant="subtitle1" gutterBottom className="intro-subtitle">
      <u>Sharing Is Caring</u>
    </Typography>
    <Grid container spacing={2}>
      {shareBlocks.map((block, index) => (
        <Grid item xs={6} sm={3} md={2} key={index}>
          <Box
            onClick={() => {
              if (block.link) {
                window.open(block.link, '_blank', 'noopener,noreferrer');
              }
            }}
            className={`card-container ${block.link ? 'clickable' : ''}`}
          >
            <Card className="card">
              <Typography 
                variant="subtitle1"
                component="h4" 
                className="card-title" 
                sx={{ textAlign: 'left', mb: 1, ml: 1, mt: 1, fontSize: '1.2 rem', fontWeight: 700 }}
              >
                {block.title}
              </Typography>
              <CardMedia
                component="img"
                image={block.image || 'https://via.placeholder.com/96'}
                alt={block.title}
                sx={{
                  width: 96,
                  height: 96,
                  margin: '16px auto 0',
                  objectFit: 'cover',
                  borderRadius: '8px',
                }}
              />
              <CardContent className="card-content">
                <Typography className="card-description">
                  {block.description}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      ))}
    </Grid>
    <hr />
    <Typography variant="subtitle1" gutterBottom className="intro-subtitle">
      <u>Advertisements</u>
    </Typography>
    <Grid container spacing={2}>
      {secondBlocks.map((block, index) => (
        <Grid item xs={6} sm={3} md={2} key={index}>
          <Box
            onClick={() => {
              if (block.link) {
                window.open(block.link, '_blank', 'noopener,noreferrer');
              }
            }}
            className={`card-container ${block.link ? 'clickable' : ''}`}
          >
            <Card className="card">
              <CardMedia
                component="img"
                image={block.image || 'https://via.placeholder.com/96'}
                alt={block.title}
                sx={{
                  width: 96,
                  height: 96,
                  margin: '16px auto 0',
                  objectFit: 'cover',
                  borderRadius: '8px',
                }}
              />
              <CardContent className="card-content">
                <Typography gutterBottom variant="subtitle1" component="h5" className="card-title">
                  {block.title}
                </Typography>
                <Typography className="card-description">
                  {block.description}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      ))}
      <Grid item xs={12} sm={6} md={3} key={blocks.length}>
        <Box>
          <Card className="card">
            <GoogleAd />
          </Card>
        </Box>
      </Grid>
    </Grid>
  </Container>
);

export default MainContent;