import React from 'react';
import { Modal, Box, IconButton, Typography, Button, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GetAppIcon from '@mui/icons-material/GetApp'; // Import the download icon

const QRCodeModal = ({ open, onClose, qrCode, title, description, link }) => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="qr-code-modal"
    aria-describedby="qr-code-description"
  >
    <Box sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
    }}>
      <IconButton
        onClick={onClose}
        sx={{ position: 'absolute', top: 8, right: 8 }}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
        {title}
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}><i>{description}</i></Typography>
      <img src={qrCode} alt="QR Code" style={{ maxWidth: '100%', height: 'auto' }} />
      
      <Box sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" href={link} target="_blank" size="small">
          Go to the website
        </Button>
        
        {/* Download Phrases Section */}
        <Box sx={{ display: 'flex', alignItems: 'center', position: 'absolute', bottom: 16, right: 16 }}>
          <IconButton
            component="a"
            href="https://drive.google.com/drive/folders/1Reu7k1jeZWCixAMn4O9LAQhdT3NzlaRD?usp=drive_link"
            target="_blank"
            aria-label="Download Phrases"
          >
            <GetAppIcon />
          </IconButton>
          <Link
            href="https://drive.google.com/drive/folders/1Reu7k1jeZWCixAMn4O9LAQhdT3NzlaRD?usp=drive_link"
            target="_blank"
            sx={{ ml: 1, textDecoration: 'none', color: '#1976d2' }} // Style the link
          >
            <Typography variant="body2">Download Phrases</Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  </Modal>
);

export default QRCodeModal;